import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CashPaymentComponent } from '../cash-payment/cash-payment.component';
import { TipDialogComponent } from '../tip-dialog/tip-dialog.component';
import { CardPaymentConsumerChoiceComponent } from '../card-payment-consumer-choice/card-payment-consumer-choice.component';
import { OrderService } from '../../_services/order.service';
import { OrdersWithPayment } from 'src/app/_models/order';
import { OrderHelpers } from 'src/app/_helpers/helpers';
import { ManualCardDialogComponent } from '../manual-card-dialog/manual-card-dialog.component';
import { Store } from 'src/app/_models/store';
import { SplitPaymentComponent } from '../split-payment/split-payment.component';
import { RefreshService } from 'src/app/_services/refresh.service';
import { OpenOrderToggleService } from 'src/app/_services/open-order-toggle.service';
import { CardPaymentMqttComponent } from '../card-payment-mqtt/card-payment-mqtt.component';
import { FrontFacingMqttService } from 'src/app/_services/front-facing-mqtt.service';
import { ManualPrintControlComponent } from '../order-summary/manual-print-control/manual-print-control.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CallerIdService } from 'src/app/_services/caller-id.service';
import { ActivateGiftCardDialogComponent } from '../activate-gift-card-dialog/activate-gift-card-dialog.component';
import { GiftCardPartialPaymentComponent } from '../gift-card-partial-payment/gift-card-partial-payment.component';

declare var iTransact;

@Component({
  selector: 'app-checkout-dialog',
  templateUrl: './checkout-dialog.component.html',
  styleUrls: ['./checkout-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckoutDialogComponent implements OnInit, OnDestroy {
  input = '';
  result = '0.00';
  dueTotal = this.data.total;
  cashDueTotal = this.data.cashTotal;
  subTotal = this.data.subTotal;
  terminalId = this.data.terminalId;
  val1;
  val2;
  val3;
  goBackSub: Subscription;
  orderHash: string;
  orderWithPayment: OrdersWithPayment;
  store: Store;
  dollarInput1: any;
  dollarInput2: any;
  dollarInput3: any;
  sub: Subscription
  openFromSplit: any;
  firstVal: any;
  seondVal: any;
  splitAmount: any;
  private destroySubject: Subject<void> = new Subject();
  paymentType: string;
  openFromgift: boolean = false;
  giftPartialAmount: any;

  constructor(public dialogRef: MatDialogRef<CheckoutDialogComponent>,
              public dialog: MatDialog,
              private orderService: OrderService,
              private refreshService: RefreshService,
              private ffmq: FrontFacingMqttService,
              private openOrderSummaryToggleService: OpenOrderToggleService,
              private snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private callerIdService: CallerIdService,
              private chRef: ChangeDetectorRef) {
    this.orderWithPayment = data.orderWithPayment;
    this.orderHash = this.orderWithPayment.bot_order.order_hash;
    this.store = this.orderWithPayment.store;
    this.openFromSplit = this.data.openFromSplit;
    this.openFromgift = data?.openFromgift;
    this.splitAmount = data.cashTotal ? data.cashTotal : ''
    this.paymentType = data?.payment_type;
    this.giftPartialAmount = data?.cashTotal;
  }

  ngOnInit() {
    this.initVals();
    console.log(this.data.from);

    this.sub = this.ffmq.paymentMode.pipe(takeUntil(this.destroySubject)).subscribe(
      data => {
        if (data.type === 'paymentTypeCard') {
          this.openCardDialog();
        } else {
          this.ffmq.publishPaymentInProgress;
        }
      }
    );
    this.goBackSub = this.ffmq.goBackMqttSub().pipe(takeUntil(this.destroySubject)).subscribe(data => {
      if (JSON.parse(data.payload.toString()).goBack == true) {
        this.dialogRef.close();
      }
    });
  }

  initVals() {
    // const valOne = Math.ceil(+this.input + this.cashDueTotal);
    this.firstVal = this.cashDueTotal;
    this.val1 = '$' + this.cashDueTotal;

    const valTwo = Math.ceil(+this.input + this.cashDueTotal);
    this.seondVal = '$' + Math.ceil(+this.input + this.cashDueTotal);
    // if (valTwo == valOne) {
    //   this.val2 = '$' + (valTwo + 1);
    // } else {
    //   this.val2 = '$' + valTwo;
    // }
    this.val2 = '$' + Math.ceil(+this.input + this.cashDueTotal);

    const valThree = (Math.ceil(+this.input + this.cashDueTotal / 10) * 10);
    if (valThree == valTwo) {
      this.val3 = '$' + (valThree + 1);
    } else {
      this.val3 = '$' + valThree;
    }
  }

  pressNum(num: string) {
    if (this.input === '') {
      this.input = '0.00';
    }

    this.dollarInput1 = '';
    this.dollarInput2 = '';
    this.dollarInput3 = '';
    const resArr: any = this.input.split('');

    const tiv: any = `${num}`.split('');
    tiv.forEach(e => resArr.push(e));
    for (let i = tiv.length; i >= 0; i--) {
      resArr[resArr.length - (3 + i)] = resArr[resArr.length - (2 + i)];
    }
    resArr[resArr.length - 3] = '.';

    if (resArr.length >= 4 && resArr[0] === '0') {
      resArr.shift();
    }
    this.input = resArr.join('');
    this.calcAnswer();
  }

  pressing(vall) {
    this.input = '0.00';
    this.firstVal = this.cashDueTotal
    this.input = this.cashDueTotal.toString();
    this.dollarInput1 = vall;
    this.dollarInput2 = '';
    this.dollarInput3 = '';
    if (this.input) {
      this.calcAnswer();
      return;
    }
  }

  pressing2(vall) {
    this.input = '0.00';
    this.input = Math.ceil(+this.input + this.cashDueTotal).toString();
    // if (this.firstVal == input) {
    //   this.input = (input + 1).toString();
    // } else {
    //   this.input = input.toString();
    // }
    this.seondVal = Math.ceil(+this.input + this.cashDueTotal);
    this.dollarInput1 = '';
    this.dollarInput2 = vall;
    this.dollarInput3 = '';
    if (this.input) {
      this.calcAnswer();
      return;
    }
  }

  pressing3(vall) {
    this.input = '0.00';
    const input = (Math.ceil(+this.input + this.cashDueTotal / 10) * 10);
    if (this.seondVal == input) {
      this.input = (input + 1).toString();
    } else {
      this.input = input.toString();
    }
    this.dollarInput1 = '';
    this.dollarInput2 = '';
    this.dollarInput3 = vall;
    if (this.input) {
      this.calcAnswer();
      return;
    }
  }

  clear() {
    if (this.input !== '0.00') {
      this.input = this.input.substr(0, this.input.length - 1);
      this.calcAnswer();
    }

    this.dollarInput1 = '';
    this.dollarInput2 = '';
    this.dollarInput3 = '';
  }

  allClear() {
    this.result = '0.00';
    this.input = '0.00';
    this.dollarInput1 = '';
    this.dollarInput2 = '';
    this.dollarInput3 = '';
  }

  calcAnswer() {
    const formula = this.input;
    // tslint:disable-next-line:no-eval
    this.result = eval(String(+formula));
  }

  getAnswer() {
    this.calcAnswer();
    this.input = this.result;
    if (this.input === '0.00') {
      this.input = '';
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
    this.ffmq.goBackMqttPub();
  }

  refreshOrder() {
    this.orderService.getOrder(this.orderHash).pipe(takeUntil(this.destroySubject))
      .subscribe((result) => {
        this.orderWithPayment = OrderHelpers.parseOrderwithPayment(result);
        console.log(this.orderWithPayment);

        this.dueTotal = this.orderWithPayment.payment.due_total;
        if (this.dueTotal <= 0) {
          this.dialogRef.close(true);
        }
        this.allClear();
        this.initVals();
      });
  }

  openCashDialog() {
    if (this.result === '0.00') {
      return;
    } else {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      const cashData = {
        cashDueTotal: this.cashDueTotal,
        result: this.result,
        from: this.data.from ? this.data.from : null,
        card_amnt: this.data.card_amount ? this.data.card_amount : null,
        card_id: this.data.card_id ? this.data.card_id : null
      };
      if (this.openFromgift ? Number(this.result) >= Number(this.cashDueTotal) : (Number(this.result) >= Number(this.orderWithPayment.payment.cash_due_total) || Number(this.result) >= Number(this.orderWithPayment.payment.due_total))) {
        const dialogRef = this.dialog.open(CashPaymentComponent, {
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '100%',
          width: '100%',
          disableClose: true,
          data: { ...this.data, ...cashData },
        });


        dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
          console.log('after closed');
          console.log(result);

          this.dialogRef.close(result);
          this.chRef.detectChanges();
        });
      } else {
        this.snackBar.open('Please select full amount', 'OK', { duration: 2000, verticalPosition: 'top' });
      }
    }
  }

  submitKeyedOrder() {
    const orderWithPayment = this.data.orderWithPayment;
    const payload = {
      terminal_id: localStorage.getItem('selectedTerminalName'),
      terminal_name: localStorage.getItem('selectedTerminalName'),
      cashier_id: localStorage.getItem('posLoggedUser'),
      amount: this.data.total,
      pin_user: localStorage.getItem('pinUser')
    };

    this.orderService.closeKeyedOrder(orderWithPayment.bot_order.order_hash, payload).pipe(takeUntil(this.destroySubject))
    .subscribe((result) => {
      this.result = result;
      localStorage.removeItem('order_hash');
      this.openOrderSummaryToggleService.toggle('CLOSE');
      localStorage.removeItem('setOccasionDetail');
      orderWithPayment.bot_order.is_closed = true;
      this.orderService.orderDoneSubject.next();
      this.refreshService.refreshModule('OPEN_TAB');
      this.dialogRef.close(true);
      this.callerIdService.setPhoneNumbertoOrderAndDelivery.next(null);
      if (this.store.feature_flag['manual_print_standard_checkout']) {
        this.manualReceiptOptions();
      }
    });
  }


  openCardDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog.closeAll();

    if (+this.result > 0) {
      this.data.toCharge = +this.result;
    } else {
      this.data.toCharge = this.dueTotal;
    }
    if (this.store.enable_consumer_choice) {
      const isMobile = window.innerWidth <= 470;
      const dialogRef = this.dialog.open(TipDialogComponent, {
        width: isMobile ? '100vw' : '100%',
        height: isMobile ? 'auto' : '100%',
        maxWidth: '100vw',
        maxHeight: '100vh',
        data: this.data, panelClass: 'trend-dialog'
      });
      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
        this.ffmq.resetTip();
        this.refreshOrder();
        this.chRef.detectChanges();
      });
    } else {
      let dialogRef;
      if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
        console.log('BBPOS chipper dialog');
        this.data.action = 'SALE';
        this.data.payment_type = 'CREDIT';
        console.log(this.data);

        dialogRef = this.dialog.open(CardPaymentMqttComponent, {
          width: '630px', data: this.data, disableClose: true,
        });
      } else {
        dialogRef = this.dialog.open(CardPaymentConsumerChoiceComponent, {
          data: this.data, disableClose: true, maxWidth: '100vw', maxHeight: '100vh', height: '100%', width: '100%',
        });
      }
      dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
        this.dialogRef.close(result);
        this.refreshOrder();
        this.chRef.detectChanges();
      });
    }
  }

  openManualCardDialog() {
    const isMobile = window.innerWidth <= 470;
    const dialogRef = this.dialog.open(ManualCardDialogComponent, {
      width: isMobile ?'100vw' : '100vw',
        height: isMobile ? 'auto' : '100vh',
        maxWidth: isMobile ? '100vw' : '',
       data: this.data, disableClose: true,
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      if (result) {
        this.dialogRef.close(result);
        this.refreshOrder();
        this.chRef.detectChanges();
      }
    });
  }

  number(str) {
    return +str;
  }

  openSplitPayment() {
    this.data.payment_type = 'SPLIT';
    const isMobile = window.innerWidth <= 470;
    const dialogRef = this.dialog.open(SplitPaymentComponent, {
      width: isMobile ? '100vw' : '',
      height: isMobile ? '100vh' : '100vh',
      maxWidth: isMobile ? '100vw' : '',

      data: this.data, disableClose: true, panelClass: ['total-balance-split-options', 'split-popup']
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      this.dialogRef.close(result);
      this.refreshOrder();
      this.chRef.detectChanges();
    });
  }

  openOtherPaymentDialog(payment_type) {
    if (this.result === '0.00') {
      return;
    } else {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      const cashData = {
        cashDueTotal: this.cashDueTotal,
        result: this.result
      };

      if (Number(this.result) >= Number(this.orderWithPayment.payment.cash_due_total) || Number(this.result) >= Number(this.orderWithPayment.payment.due_total)) {
        const isMobile = window.innerWidth <= 470;
        const dialogRef = this.dialog.open(CashPaymentComponent, {
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '100%',
          width: '100%',
          disableClose: true,
          data: { ...this.data, ...cashData, is_other: true, split_type: payment_type }
        });


        dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
          console.log('after closed');
          console.log(result);

          this.dialogRef.close(result);
          this.chRef.detectChanges();
        });
      } else {
        this.snackBar.open('Please select full amount', 'OK', { duration: 2000, verticalPosition: 'top' });
      }
    }
  }

  splitCashPayment() {
    if (this.result === '0.00') {
      return;
    } else {
      const cashData = {
        cashDueTotal: this.cashDueTotal,
        result: this.result
      }
      this.dialogRef.close(cashData);
    }
  }
  manualReceiptOptions() {
    const isMobile = window.innerWidth <= 470;
    const dialogRef = this.dialog.open(ManualPrintControlComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        value: 1,
        order_hash: this.orderHash,
        key: 'manual_card'
      }
    });
    dialogRef.afterClosed().subscribe((selectVal: any) => {
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
    if (this.goBackSub) {
      this.goBackSub.unsubscribe()
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.ffmq.paymentModeSubject.next(0);
  }

  openGiftCardDialog() {
    const isMobile = window.innerWidth <= 599;
    const dialogRef = this.dialog.open(ActivateGiftCardDialogComponent, {
      disableClose: true,
      width: isMobile ? '100vw' : '600px',
      data: { for: 'gift-card-redeem', amount: this.data.total, store: this.orderWithPayment.store },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data) {
        this.giftPayment(result);
      }
    });
  }

  giftPayment(r) {
    const orderWithPayment = this.data.orderWithPayment;
    if (this.data.total >= r.cardDetails.balance) {
      const dialogData = {
        amnt: this.data.total,
        card_blnce: r.cardDetails.balance,
        card_id: r.cardDetails.id,
        order: orderWithPayment,
        subTotal: this.data.subTotal,
        terminalId: this.data.terminalId,
      }
      const dialogRef = this.dialog.open(GiftCardPartialPaymentComponent, {
        width: '500px',
        data: { "data": dialogData },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result.data) {
          if (result.type === 'card') {
            this.partialCardPay(Number((this.data.total - r.cardDetails.balance).toFixed(2)), r.cardDetails.balance, r.cardDetails.id);
          }
        }
      });
      //   const orderWithPayment = this.data.orderWithPayment;
      //   const payload = {
      //     terminal_id: localStorage.getItem('selectedTerminalName'),
      //     terminal_name: localStorage.getItem('selectedTerminalName'),
      //     cashier_id: localStorage.getItem('posLoggedUser'),
      //     amount: r.cardDetails.balance,
      //     pin_user: localStorage.getItem('pinUser'),
      //     card_id: r.cardDetails.id
      //   };
      //   this.orderService.closeGiftCardOrder(orderWithPayment.bot_order.order_hash, payload).pipe(takeUntil(this.destroySubject))
      //     .subscribe((result) => {

      //       if (result) {
      //         const dialogData = {
      //           amnt: this.data.total,
      //           card_blnce: r.cardDetails.balance,
      //           card_id: r.cardDetails.id,
      //           order: orderWithPayment,
      //           subTotal: this.data.subTotal,
      //           terminalId: this.data.terminalId,
      //         }
      //         const dialogRef = this.dialog.open(GiftCardPartialPaymentComponent, {
      //           width: '500px',
      //           data: { "data": dialogData },
      //         });
      //         dialogRef.afterClosed().subscribe((result: any) => {
      //           if (result.data) {
      //             if (result.type === 'card') {
      //               this.partialCardPay(Number((this.data.total - r.cardDetails.balance).toFixed(2)));
      //             }
      //           }
      //         });
      //       }
      //     });
      // } else {
      //   const orderWithPayment = this.data.orderWithPayment;
      //   const payload = {
      //     terminal_id: localStorage.getItem('selectedTerminalName'),
      //     terminal_name: localStorage.getItem('selectedTerminalName'),
      //     cashier_id: localStorage.getItem('posLoggedUser'),
      //     amount: this.data.total,
      //     pin_user: localStorage.getItem('pinUser'),
      //     card_id: r.cardDetails.id
      //   };
      //   this.orderService.closeGiftCardOrder(orderWithPayment.bot_order.order_hash, payload).pipe(takeUntil(this.destroySubject))
      //     .subscribe((result) => {
      //       if (result) {
      //         this.dialogRef.close(true);
      //         if (this.store.feature_flag['manual_print_standard_checkout']) {
      //           this.manualReceiptOptions();
      //         }
      //       }
      //     });
    } else {
      const orderWithPayment = this.data.orderWithPayment;
      const payload = {
        terminal_id: localStorage.getItem('selectedTerminalName'),
        terminal_name: localStorage.getItem('selectedTerminalName'),
        cashier_id: localStorage.getItem('posLoggedUser'),
        amount: this.data.total,
        pin_user: localStorage.getItem('pinUser'),
        card_id: r.cardDetails.id
      };
      this.orderService.closeGiftCardOrder(orderWithPayment.bot_order.order_hash, payload).pipe(takeUntil(this.destroySubject))
        .subscribe((result) => {
          if (result) {
            this.dialogRef.close(true);
            if (this.store.feature_flag['manual_print_standard_checkout']) {
              this.manualReceiptOptions();
            }
          }
        });
    }
  }

  partialCardPay(r, balance, id) {
    const isMobile = window.innerWidth <= 470;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog.closeAll();
    let dialogRef = null;

    const dialogData = {
      from: 'gift-partial-payment',
      card_amnt: balance,
      card_id: id
    }
    if (this.store.pos_payment_gateway === 'NMI_BBPOS') {
      this.data.amount = r;
      this.data.action = 'TIP';
      dialogRef = this.dialog.open(CardPaymentMqttComponent, {
        // width: '630px',
        width: isMobile ? '100vw' : '630px',
        height: isMobile ? '50%' : '600px',
        maxWidth: isMobile ? '100vw' : '',
        data: { ...this.data, ...dialogData }, disableClose: true
      });
    } else {
      this.data.toCharge = r;
      dialogRef = this.dialog.open(CardPaymentConsumerChoiceComponent, {
        maxWidth: '100vw', maxHeight: '100vh', height: '100%', width: '100%',
        data: { ...this.data, ...dialogData }, disableClose: true
      });
    }
    dialogRef.afterClosed().pipe(takeUntil(this.destroySubject)).subscribe(result => {
      this.refreshOrder();
      this.chRef.detectChanges();
    });
  }

}
