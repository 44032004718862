import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class PwaUpdateService {
  private currentVersion: string = '';

  constructor(private http: HttpClient) {
    console.log('PwaUpdateService constructor called');
    console.log('PwaUpdateService initialized');
    this.startVersionPolling();
  }

  startVersionPolling(): void {
    console.log('Polling function entered');

    const pollingInterval = 60000; // 1 minute for testing (set to 300000 for 5 minutes in production)

    setInterval(() => {
      console.log('Polling interval running...');
      console.log('Checking for updates...');
      // Add cache-busting query string to avoid stale cached version.json
      this.http.get<{ version: string }>(`/assets/version.json?v=${Date.now()}`).subscribe(  // Use backticks ``
        (data) => {
          const latestVersion = data.version;
          console.log('Current version:', this.currentVersion, 'Latest version:', latestVersion);
          if (this.currentVersion && this.currentVersion !== latestVersion) {
            console.log('New version detected:', latestVersion);
            this.promptUserToUpdate(latestVersion);
          }
          this.currentVersion = latestVersion;  // Set the current version after checking
        },
        (error) => {
          console.error('Error fetching version.json:', error);
        }
      );
    }, pollingInterval);  // Set interval for polling
  }

  promptUserToUpdate(newVersion: string): void {
    if (confirm(`A new version (v${newVersion}) is available. Reload to update?`)) {  // Use backticks ``
      this.activateUpdate();
    } else {
      console.log('User declined to update.');
    }
  }

  activateUpdate(): void {
    console.log('Reloading to update the app...');
    window.location.reload(); // Reload to update the PWA
  }
}
