import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent, VersionReadyEvent } from '@angular/service-worker';

@Injectable({
  providedIn: 'root'
})
export class PwaUpdateService {
  private currentVersion: string = '';

  constructor(private swUpdate: SwUpdate) {
    console.log('PwaUpdateService initialized');
    this.listenForUpdates();
    this.startVersionPolling();
  }

  listenForUpdates(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event: VersionEvent) => {
        if (event.type === 'VERSION_READY') {
          console.log('New update available:', event.latestVersion);
          this.promptUserToUpdate();
        }
      });
    } else {
      console.log('Service worker is not enabled.');
    }
  }

  startVersionPolling(): void {
    console.log('Polling function entered');

    const pollingInterval = 60000; // 1 minute for testing (set to 300000 for production)

    setInterval(() => {
      console.log('Polling interval running...');
      console.log('Checking for updates...');
      // Add cache-busting query string to avoid stale cached version.json
      fetch(`/assets/version.json?v=${Date.now()}`)
        .then(response => response.json())
        .then((data: { version: string }) => {
          const latestVersion = data.version;
          console.log('Current version:', this.currentVersion, 'Latest version:', latestVersion);
          if (this.currentVersion && this.currentVersion !== latestVersion) {
            console.log('New version detected:', latestVersion);
            this.promptUserToUpdate();
          }
          this.currentVersion = latestVersion;  // Set the current version after checking
        })
        .catch(error => {
          console.error('Error fetching version.json:', error);
        });
    }, pollingInterval);
  }

  promptUserToUpdate(): void {
    if (confirm('A new version is available. Reload to update?')) {
      this.activateUpdate();
    } else {
      console.log('User declined to update.');
    }
  }

  async activateUpdate(): Promise<void> {
    try {
      console.log('Activating update...');
      await this.swUpdate.activateUpdate();
      console.log('Update activated, reloading the app...');
      window.location.reload();
    } catch (error) {
      console.error('Error activating update:', error);
      window.location.reload();  // Fallback to reload if activation fails
    }
  }
}
