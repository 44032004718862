import { Component } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { CallerIdService } from '../_services/caller-id.service';
import { StoreService } from '../_services/store.service';
import { Store } from '../_models/store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-call-card',
  templateUrl: './call-card.component.html',
  styleUrls: ['./call-card.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('300ms ease-out', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class CallCardComponent {
  incomingCallArray: any[] = [];
  private destroySubject: Subject<void> = new Subject();
  storeSub: Subscription;
  store: Store;
  terminalname: string;

  constructor(
    private callerIdService: CallerIdService,
    private storeService: StoreService,
    private router: Router
  ) { }

  ngOnInit() {
    this.storeSub = this.storeService.current.pipe(takeUntil(this.destroySubject)).subscribe((store) => {
      if (store) {
        this.store = Object.assign(new Store(), store);
      }
    });

    this.callerIdService.callSet.pipe(takeUntil(this.destroySubject)).subscribe((result: any) => {
      if (result.length !== 0) {
        this.terminalname = localStorage.getItem('selectedTerminalName');
        if (this.store) {
          if (this.store.feature_flag['HYBRID_CALLER_ID'] && this.terminalname === result.station) {
            this.updateIncomingCalls(result.data);
          } else if (!this.store.feature_flag['HYBRID_CALLER_ID']) {
            console.log('UPDATE INCOMING CALL');

            this.updateIncomingCalls(result.data);
          }
        }
      }
    });
  }

  updateIncomingCalls(newCalls: any[]): void {
    console.log('Update icoming call function', this.incomingCallArray);

    // Remove calls that are no longer in the incoming list
    this.incomingCallArray = this.incomingCallArray.filter((existingCall) =>
      newCalls.some((newCall) => newCall.call_id === existingCall.call_id)
    );


    // Add new calls to the array
    newCalls.forEach((newCall) => {
      const existingCall = this.incomingCallArray.find((call) => call.call_id === newCall.call_id);
      console.log('Existing call', existingCall);

      if (!existingCall) {
        this.incomingCallArray.push(newCall);
      }
    });

    console.log('Update after removing or adding the call', this.incomingCallArray);

  }

  handleIgnore(call: any): void {
    this.incomingCallStatusUpdate(call, 'Missed');
  }

  handleAccept(call: any): void {
    this.incomingCallStatusUpdate(call, 'Answered');
  }

  handleAvoid(call: any): void {
    this.incomingCallStatusUpdate(call, 'Avoided');
  }

  incomingCallStatusUpdate(call: any, status: string): void {
    const payload = {
      callId: call.call_id,
      eventType: status
    };

    this.callerIdService.updateCallStatus(payload).pipe(takeUntil(this.destroySubject)).subscribe(() => {
      const index = this.incomingCallArray.findIndex((n) => n.call_id === call.call_id);
      if (index > -1) {
        setTimeout(() => {
          this.incomingCallArray.splice(index, 1);
        }, 300);
      }

      if (status === 'Answered') {
        if (window.location.hash === '#/home/orders') {
          this.callerIdService.acceptIgnreCall.next(true);
          setTimeout(() => {
            this.callerIdService.setPhoneNumbertoOrderAndDelivery.next(call);
          }, 2000);
        } else {
          this.router.navigate(['/home/orders']);
          setTimeout(() => {
            this.callerIdService.setPhoneNumbertoOrderAndDelivery.next(call);
          }, 2000);
        }
      }
    });
  }

  trackByCallId(index: number, call: any): string {
    return call.call_id;
  }
}

